import usePartnerImages from './usePartnerImages';

export default function usePartners() {
  const partnerImages = usePartnerImages();
  const partners = {
    tokeportal: {
      title: 'Tőkeportál',
      logo: partnerImages.tokeportal,
      offers: [
        {
          title: 'Online fogadóóra',
          lead:
            'Online fogadóóra heti rendszerességgel csütörtökönként reggel 8:00-9:00-ig: Tőkebevonási stratégia, közösségi finanszírozás témájában.'
        },
        {
          title: 'Webinár közösségi finanszírozás témában',
          lead:
            'Online webinár tartása egy alkalommal korai fázisú tőkebevonás témájában, fő fókuszban a közösségi finanszírozás.'
        },
        {
          title: 'Közösségi finanszírozási kampány indítása',
          lead:
            '1 projektcsapat számára biztosítja a közösségi finanszírozási kampány elindítását.'
        },
        {
          title: 'Workshop IP témában',
          lead:
            'Workshopot tart (online vagy offline formában) szellemi tulajdon témakörben.'
        },
        {
          title: 'Workshop közösségi finanszírozás témában',
          lead:
            'Workshopot tart (online vagy offline formában) közösségi finanszírozás, crowdfunding témakörben.'
        }
      ]
    },
    bosch: {
      title: 'Bosch',
      logo: partnerImages.bosch,
      offers: [
        {
          title: 'Szakmai mentoráció',
          lead:
            'A HSUP Programban kidolgozásra kerülő - Bosch által javasolt vagy támogatásra érdemesnek ítélt - témák számához igazodóan mentorokat biztosít a hallgatók számára.'
        },
        {
          title: 'Ipari szakértői konzultáció',
          lead:
            'Ipari szakértői konzultációs lehetőséget ajánl fel a Bosch által javasolt vagy általa támogatásra érdemesnek ítélt témá(k)hoz kapcsolódóan a témán dolgozó hallgató(k) számára.'
        }
      ]
    },
    oxo: {
      title: 'OXO Labs',
      logo: partnerImages.oxo,
      offers: [
        {
          title: 'Szakmai konzultáció',
          lead:
            '3 HSUP csapat számára 2 órás konzultációs lehetőséget biztosít.'
        },
        {
          title: 'Szakmai konzultáció',
          lead: 'Szakmai mentort delegál a HSUP programba.'
        }
      ]
    },
    lunar: {
      title: 'Lunar Program',
      logo: partnerImages.lunar,
      offers: [
        {
          title:
            'Szakmai visszajelzés EIT Digital Venture Programba jelentkezéskor',
          lead:
            'EIT Digital Venture Programba a HSUP-ból jelentkező csapatok számára visszajelzést ad a jelentkezési anyagukra a leadást megelőzően, hogy jobb esélyekkel indulhassanak.'
        },
        {
          title:
            'Lunar Program inkubátorába, a Design Station-be való meghívás',
          lead:
            'A HSUP Demo Day-be bekerült csapatok jelentkezhetnek, meghívást kaphatnak a Lunar Program inkubátorába, a Design Station-be, ahol tovább vihetik ötleteik kidolgozását.'
        }
      ]
    },
    vespucci: {
      title: 'Vespucci Partners',
      logo: partnerImages.vespucci,
      offers: [
        {
          title: 'Demo Day-en szakértő',
          lead:
            'A HSUP zárásaként tartott Demo Day rendezvényre szakértőt delegál.'
        },
        {
          title: 'Pitch-elési lehetőség',
          lead:
            'Az eseményen résztvevő, legfeljebb 3 kiválasztott csapat számára a Vespucci befektetési csapata előtti pitch-elési lehetőséget biztosít.'
        },
        {
          title: 'Befektetői feedback',
          lead:
            'A legfeljebb 3 kiválasztott csapat befektetői prezentációit átnézi és befektetői szempontból releváns visszajelzésekkel látja el.'
        }
      ]
    },
    mbh: {
      title: 'MBH Inkubátor',
      logo: partnerImages.mbh,
      offers: [
        {
          title: 'Exklúzív szakmai támogatás',
          lead:
            'BBefektetői csapat által nyújtott exkluzív szakmai támogatást nyújt maximum 5 HSUP hallgatói csapat számára'
        },
        {
          title: 'Banki sandbox hozzzáférés',
          lead: 'Hozzáférést biztosít a banki sandbox-hoz 3 hallgató számára'
        }
      ]
    },
    digitalFactory: {
      title: 'Digital factory',
      logo: partnerImages.digitalFactory,
      offers: [
        {
          title: 'Prototipizálási tanácsadás',
          lead:
            'Egy HSUP projekt csapat számára, előre egyeztetett időpontban konzultációt és tanácsadást biztosít a vállalkozási ötlet prototipizálásához.'
        },
        {
          title: 'Mentorok biztosítása',
          lead: 'Mentorokat biztosít a programba.'
        }
      ]
    },
    startitup: {
      title: 'Startitup Győr',
      logo: partnerImages.startitup,
      offers: [
        {
          title: 'Startup tanácsadás',
          lead:
            'Egy órás online meeting (3 csapat részére): egy órás startup tanácsadás a Start IT up Győr szakmai csapatával, egyéni konzultáció online, szakmai visszajelzések az ötletekkel kapcsolatban.'
        },
        {
          title: 'IDEATHON részvétel',
          lead:
            'Háromnapos ötletverseny Győrben (maximum 6 fő részére): részvételi lehetőség a 2024-es IDEATHON ötletversenyen, szállás biztosítása a résztvevőknek a verseny ideje alatt Győrben.'
        }
      ]
    },
    efott: {
      title: 'EFOTT',
      logo: partnerImages.efott,
      offers: [
        {
          title: 'Megjelenési lehetőség',
          lead:
            'Fesztiválhoz köthető az innovatív ötleted? Az EFOTT lehetőséget biztosít a következő fesztiválon hogy megmutathassátok amin a félévben dolgoztatok!  '
        }
      ]
    },
    szintézis: {
      title: 'Szintézis-Net Kft.',
      logo: partnerImages.szintezis,
      offers: [
        {
          title: 'Üzletfejlesztési és/vagy termékfejlesztési tanácsadás',
          lead:
            '2 órás, ingyenes üzletfejlesztési és/vagy termékfejlesztési tanácsadást biztosít 3 HSUP projekt csapat számára.'
        },
        {
          title: 'Mentorok biztosítása',
          lead: 'Mentorokat biztosít a HSUP projekt csapatoknak.'
        }
      ]
    },
    drukka: {
      title: 'Drukka Kft. és Intellitext Kft.',
      logo: partnerImages.drukka,
      offers: [
        {
          title: 'Kötetlen szakmai beszélgetés',
          lead:
            'Meghív egy ebédre egy teljes csapatot egy előre egyeztetett időpontban UX/UI designerükkel, front vagy backend fejlesztővel. Kérdezz tőlük bármit és lakj jól! '
        },
        {
          title: 'Havi Hub55 coworking bérlet',
          lead:
            'Előre egyeztetett időpontban egy havi Hub55 coworking bérlet: free wifi, gamer monitor és all you can drink, cafee, snack kiegészítéssel.'
        },
        {
          title: 'Akceleració részvétel',
          lead:
            'Founder Institute akceleració részvétel a 2. cohortban (részletek a kapcsolatfelvétel után)'
        }
      ]
    },
    mol: {
      title: 'MOL',
      logo: partnerImages.mol,
      offers: [
        {
          title: 'Mentoráció',
          lead:
            'Szakmai mentorálással 3 óra ingyenes ipari validációt, illetve go-to-market stratégiai tanácsadást biztosít maximum 4 HSUP projektcsapat számára, a vállalat Tech Transfer szervezetével.'
        },
        {
          title: 'Mentorok biztosítása',
          lead: 'Mentorokat biztosít a program számára.'
        }
      ]
    },
    smartFutureLab: {
      title: 'MVM Smart Future Lab',
      logo: partnerImages.smartFutureLab,
      offers: [
        {
          title: 'Watts.UP gyakornoki programok',
          lead:
            'a) Alap- és mesterszakos egyetemi hallgatóknak számos gyakorlati, munka és tanulási lehetőséget kínál az MVM csoport a Watt.s Up gyakornoki programokon keresztül. A résztvevők az energia szakértőinek támogatásával fejlődhetnek, alapozhatják meg karrierjüket, és válhatnak az energiaszektor szakértőivé, vezetőivé. b) Szakdolgozatot készítő gyakornokaink számára szakmai konzulens biztosítása'
        },
        {
          title: 'Mentorálás',
          lead: 'Mentorálás az MVM Smart Future Lab támogatásával.'
        },
        {
          title: 'Inkubációs Program',
          lead:
            'Ígéretes ötletek esetén az MVM Smart Future Lab inkubációs programjába való meghívás'
        },
        {
          title: 'Konzultáció',
          lead:
            'Projekt konzultáció (alkalmanként) az MVM Smart Future Lab támogatásával.'
        }
      ]
    },
    startupCampus: {
      title: 'Startup Campus',
      logo: partnerImages.startupCampus,
      offers: [
        {
          title: 'Szakmai mentoráció + üzletfejlesztési tanácsadás',
          lead:
            'Szakmai mentort biztosít korai fázisú startup vállalkozások száára. Vállalkozásfejlesztési, üzletfejlesztési tanácsadás a Startup Campus szakembereitől (3 fő)'
        }
      ]
    },
    nak: {
      title: 'NAK TechLab',
      logo: partnerImages.nak,
      offers: [
        {
          title: 'Agrárszakmai konzultáció',
          lead:
            'Agrár releváns projektek számára agrárszakmai mentorálást (állattenyésztés és szántóföldi növénytermesztés területén) biztosít havonta egy alkalommal.'
        },
        {
          title: 'Részvételi lehetőség NAK TechLab rendezvényen',
          lead:
            'Az arra érett projektek számára bemutatkozási lehetőség a NAK TechLab egy rendezvényén (pl. befektetői esemény).'
        }
      ]
    },
    bnlStart: {
      title: 'BNL Start Partners Kft.',
      logo: partnerImages.bnlStart,
      offers: [
        {
          title: 'Részvételi lehetőség a BNL Start rendezvényeken',
          lead:
            '2-3 hallgatói csapat számára ingyenes részvétell lehetőséget biztosít a BNL Start által szervezett vagy támogatott rendezvényeken.'
        },
        {
          title: 'Szakmai tanácsadás',
          lead:
            'Előzetes egyeztetés alapján tanévenként 2x30 perces szakmai tanácsadást biztosít max. 3 hallgatói csapat számára.'
        }
      ]
    },
    startItKh: {
      title: 'Start it @K&H',
      logo: partnerImages.startItKh,
      offers: [
        {
          title: '"Zöld kártya" az inkubátor őszi kiválasztásában',
          lead:
            'A legkiemelkedőbb, általuk kiválasztott 2 csapat „zöld kártyát” kap az inkubátor őszi kiválasztásában, és egyből az utolsó fordulóba kerülnek.'
        },
        {
          title: 'Közösségi iroda használat',
          lead:
            'A hallgatók belátást kaphatnak az inkubátorba: előzetes egyeztetés után használatba vehetik a közösségi irodát egy-egy napra.'
        }
      ]
    },
    checkInnTurisztikai: {
      title: 'CheckINN Kft.',
      logo: partnerImages.checkInnTurisztikai,
      offers: [
        {
          title: 'Turisztikai szakmai mentoráció',
          lead:
            '5 hallgatói csapat számára 2x2 óra turisztikai szakmai mentorálást biztosít.'
        },
        {
          title: 'Ingyenes éves előfizetés szakmai lapokra',
          lead:
            'A Turizmus Kft. által kiadott szakmai lapokra történő éves előfizetést biztosít a mentorált csapatok számára. (1 db újság választható / csapattag: Turizmus.com magazin / Business Traveller / Vendéglátás magazin).'
        }
      ]
    },
    laserConsult: {
      title: 'Laser Consult Kft.',
      logo: partnerImages.laserConsult,
      offers: [
        {
          title: 'Találkozási lehetőség angyalbefektetőkkel',
          lead:
            'Kiválasztott hallgatói csapatok számára lehetőséget biztosít angyalbefektetőkkel való kapcsolatfelvételre.'
        },
        {
          title: 'Workshop',
          lead: 'Workshopot tart hallgatói csapatok számára.'
        }
      ]
    },
    creativeAccelerator: {
      title: 'Creative Accelerator',
      logo: partnerImages.creativeAccelerator,
      offers: [
        {
          title: 'Befektetés szerzési lehetőség',
          lead:
            'Kiválasztott hallgatói csapatok részére, akik az élettudomány területén tevékenykednek, befektetési lehetőséget biztosít, amennyiben a projektet érdemesnek találja.'
        },
        {
          title: 'Workshop',
          lead:
            'Workshopot tart hallgatói csapatok számára befektetéshez kapcsolódó témakörben.'
        }
      ]
    },
    startupHungary: {
      title: 'Startup Hungary Alapítvány',
      logo: partnerImages.startupHungary,
      offers: [
        {
          title: 'Workshop',
          lead:
            'Évente 1-2 alkalommal dedikált workshopot szervez 20 HSUP csapat számára, melynek keretében hasznos gyakorlati tudást szerezhetnek az iparág legelismertebb képviselőitől.'
        },
        {
          title: 'Szakmai mentoráció',
          lead: '1 szakmai mentor biztosít a HSUP-ba.'
        },
        {
          title: 'Konzultációs lehetőség',
          lead:
            '2 órás konzultációs lehetőséget biztosít 3 HSUP csapat számára.'
        },
        {
          title: 'Bemutatkozási lehetőség First Monday eseményen',
          lead:
            '1 HSUP csapat számára bemutatkozási lehetőséget biztosít egy First Monday eseményen.'
        }
      ]
    },
    designTerminal: {
      title: 'Design Terminal Nonprofit Kft.',
      logo: partnerImages.designTerminal,
      offers: [
        {
          title: 'Szakmai mentoráció',
          lead: 'Szakmai mentorokat biztosít'
        },
        {
          title: 'HSUP Alumni',
          lead: 'HSUP Alumni közösségépítő napot szervez és bonyolít le.'
        }
      ]
    },
    izinta: {
      title: 'Izinta Kft.',
      logo: partnerImages.izinta,
      offers: [
        {
          title: 'Egészségipari szakmai konzultáció',
          lead:
            '30 éves egészségipari tapasztalatával 2x2 órás lehetőséget biztosít a hallgatóknak, hogy a cég szakterületihez kapcsolódó kérdéseket fogalmazzanak meg és a válaszokból nyert szaktudást leendő projektjeiben hasznosítsák.'
        },
        {
          title: 'Üzleti ebéd',
          lead:
            'Üzleti ebédet szervez Budapesten az Izinta Kft. vezetőivel, akik egészségipari - üzleti tanácsokkal látják el a hallgatókat. A hallgatók feltehetik kérdéseiket, az ebéd során projektjeik szóban véleményezésére kerülnek a vezetők tapasztalati alapján.'
        }
      ]
    },
    ivsz: {
      title: 'IVSZ',
      logo: partnerImages.ivsz,
      offers: [
        {
          title: 'Szakmai mentoráció',
          lead:
            'Szakmai mentort biztosít a vállalati  kihívásukon dolgozó HSUP csapat számára.'
        },
        {
          title: 'Rendezvényen való részvétel',
          lead:
            'Az IVSZ által szervezett rendezvényeken (pl. SMART) ingyenes részvételi lehetőség (20 hallgató száméra) és egyedi kedvezmény a HSUP hallgatók számára '
        },
        {
          title: 'Megjelenítési lehetőség',
          lead:
            'Két, IVSZ által kiválasztott csapat számára megjelenési lehetőség biztosítása a SMART vagy egyéb saját rendezésű, start-up fókuszú rendezvényen'
        }
      ]
    },
    greenBrother: {
      title: 'Green Brother',
      logo: partnerImages.greenBrother,
      offers: [
        {
          title: 'Mentorálás',
          lead:
            '2 mobilitással és 2 energiával foglalkozó csapatok számára, 10 óra mentorálás/csapat) - tavaszi félév'
        },
        {
          title: 'Konzultáció',
          lead:
            '2 legjobb csapat számára EIT InnoEnergy/EIT Urban Mobility befektetési folyamatokról nyújt konzultációt'
        },
        {
          title: 'Hozzáférés modulokhoz',
          lead:
            'A legjobb csapatok (5 energia, 5 mobilitás) számára ingyenes hozzáférést biztosít atavaszi félév után a Green Brother modulokhoz - (https://greenbrother.hu/)'
        }
      ]
    },
    meOutGroup: {
      title: 'MeOut Group',
      logo: partnerImages.meOutGroup,
      offers: [
        {
          title: 'Közös ebéd',
          lead:
            'Meghívás egy közös ebédre (Szeged vagy Budapest - "céglátogatás") ahol a termék/szolgáltatás bemutatásra kerülhet és ahol adott esetben további expertekkel vagy iparági szereplőkkel tudjuk összekötni a csapatot.'
        },
        {
          title: 'Szakmai konzultáció',
          lead:
            '2x1 óra (alapesetben online) mentorálás pitch-deck, onepager készítésben.'
        }
      ]
    },
    brancsKozosseg: {
      title: 'Brancsközösség.hu Zrt.',
      logo: partnerImages.brancsKozosseg,
      offers: [
        {
          title: 'Alumni Pitch night + kedvezményes kampányindítás',
          lead:
            'A pitch videót beküldő HSUP alumnis csapatok közül félévente 10-et kiválaszt, akik egy pitch night-on versenghetnek egymással. A legjobb 3 csapat kedvezményes kampányindítási lehetőségben részesül a Brancs közösségi piactéren (jelentkezéshez a levél tárgyában fel kell tüntetni, hogy HSUP Alumni).'
        },
        {
          title: 'Közös ebéd',
          lead:
            'Egy alkalommal közös ebéddel összekötött tanácsadást szervez a Brancs és a MyFarm Harta startupok megálmodójával, Kövesdi Gáborral vagy a brancs piactér ügyvezetőjével'
        },
        {
          title: 'Marketing tanácsadás',
          lead:
            '1 órás tanácsadást biztosít marketing témában a Brancs közösségi piactér marketing vezetőjével.'
        },
        {
          title: 'Kedvezményes kampányindítási lehetőség',
          lead:
            'Kedvezményes kampányindítási lehetőséget biztosít a Brancs közösségi piactéren a félév végén a Demo Day-en a Brancs kedvenc csapatának.'
        }
      ]
    },
    impactHubBp: {
      title:
        'Impact Hub Budapest FVA - Fenntartható Vállalkozásokért Alapítvány',
      logo: partnerImages.impactHubBp,
      offers: [
        {
          title: 'Szakmai tanácsadás pénzügyi tervezés témában',
          lead:
            '10 HSUP csapat számára (csapatonként 1 alkalommal) 90 perces szakmai tanácsadást biztosít pénzügyi tervezés témában.'
        },
        {
          title: 'Workshop',
          lead: 'Workshopot tart "társadalmi hatás és annak mérése" témában.'
        },
        {
          title: 'Networking helyszín',
          lead:
            'Networking eseményre egy alkalommal coworking helyet tud biztosítani előzetes időpont egyeztetés alapján.'
        },
        {
          title: 'Teremhasználati lehetőség',
          lead:
            'Ingyenes teremhasználati lehetőség ajánl fel HSUP csapatok számára előzetes egyeztetés alapján a 3. emeleti tárgyalóban az Impact Hub Budapest-ben.'
        }
      ]
    },
    startupPlastic: {
      title: 'Startup Plastic Surgery',
      logo: partnerImages.startupPlastic,
      offers: [
        {
          title: 'Klímahatás vizsgálat',
          lead:
            'Greentech területen dolgozó csapatoknak klímahatás vizsgálatot ajánl fel, ami segít, hogy a csapatok megállapíthassák a termék/szolgáltatás környezetre gyakorolt hatását.'
        },
        {
          title: 'Szakmai konzultáció',
          lead:
            'Szakmai konzultációt biztosít a greentech (főként műanyag) területen dolgozó csapatoknak.'
        }
      ]
    },
    eitHealth: {
      title: 'EIT Health InnoStarts',
      logo: partnerImages.eitHealth,
      offers: [
        {
          title: 'Mentorálás és coaching',
          lead:
            'Mentorálást és coaching szolgáltatást nyújt azon HSUP csapatok számára, melyek az egészségügy területén dolgoznak projektötlet megvalósításán.'
        },
        {
          title: 'Business Creation programokban való részvételi lehetőség',
          lead:
            'Széleskörű tájékoztatást és részvételi lehetőséget kínál a Business Creation programjaikba az egészségügyi projektötlettel rendelkező HSUP csapatok számára (a felvétel a programokba nem garantált). '
        }
      ]
    },
    cibBank: {
      title: 'CIB Bank',
      logo: partnerImages.cibBank,
      offers: [
        {
          title: 'Gyakornoki lehetőség',
          lead:
            'Gyakornoki pályázási lehetőségeket kínál a HSUP hallgatók számára.'
        },
        {
          title: 'Szakmai mentoráció',
          lead: 'Szakmai mentort biztosít a programba.'
        }
      ]
    },
    pozi: {
      title: 'Pozi',
      logo: partnerImages.pozi,
      offers: [
        {
          title: 'Szakmai brunch szervezése',
          lead: 'Szakmai brunchot szervez 20 HSUP hallgató számára.'
        },
        {
          title: 'Workshop tartása',
          lead: 'Online workshopot tart HSUP hallgatók számára'
        },
        {
          title: 'Ötletvalidációs és pitch tréning',
          lead:
            'Ötletvalidációs és pitch tréninget biztosít a HSUP Demo Day-en részt vevő csapatok számára.'
        },
        {
          title: 'Kedvezményes belépőjegyek',
          lead:
            'Külföldi partnereik által megrendezett eseményekre szóló belépőjegyek árából 15%-os kedvezményt kínál a HSUP hallgatók számára. '
        },
        {
          title: 'Ingyenes részvételi lehetőség',
          lead:
            'A Startup World Cup budapesti elődöntőjében ingyenes részvételi lehetőséget biztosít valamennyi HSUP alumni csapat számára.'
        }
      ]
    },
    vivold: {
      title: 'Vivold Consulting',
      logo: partnerImages.vivold,
      offers: [
        {
          title: 'Szakmai konzultáció',
          lead:
            '3x1 órás ingyenes konzultáció 2 HSUP-csapat számára projektmenedzsment, termékmenedzsment, terméktervezés és stratégiai tanácsadás témákban.'
        },
        {
          title: 'Termékismertető workshop',
          lead:
            'Termékismertető workshop 2 csapat számára, minden workshop 2-4 órát vehet igénybe a kiválasztott terméktől függően.'
        },
        {
          title: 'Pitch előkészítés',
          lead: '2x1 órás pitch-előkészítés 2 HSUP-csapat számára.'
        }
      ]
    },
    futureproof: {
      title: 'Future Proof Consulting Kft.',
      logo: partnerImages.futureproof,
      offers: [
        {
          title: 'Workshop tartása',
          lead:
            'Szakmai workshopot tart "vezetői kihívások startuppereknek" témában, melyet követően 1 projektvezető számára egyéni konzultációt is biztosít.'
        }
      ]
    },
    diverzum: {
      title: 'Diverzum',
      logo: partnerImages.diverzum,
      offers: [
        {
          title: 'Workshop',
          lead: 'Szakmai workshop tartása.'
        }
      ]
    },
    drtanacs: {
      title: 'Dr. Tanács Ügyvédi Iroda',
      logo: partnerImages.tanacs,
      offers: [
        {
          title: 'Jogi tanácsadás',
          lead: 'Jogi tanácsadást biztosít HSUP csapatok számára.'
        },
        {
          title: 'Workshop tartása',
          lead: 'Workshopot tart "Jogi kérdések startupoknak" témában.'
        }
      ]
    },
    sztnh: {
      title: 'SZTNH',
      logo: partnerImages.sztnh,
      offers: [
        {
          title: 'Szakmai konzultáció',
          lead:
            'Szakmai konzultációt biztosít 4 HSUP csapat számára szellemitulajdon-védelem témában.'
        },
        {
          title: 'Workshop tartása',
          lead: 'Szakmai workshopot tart szellemi tulajdon témában.'
        }
      ]
    },
    hunban: {
      title: 'HunBAN',
      logo: partnerImages.hunban,
      offers: [
        {
          title: 'Workshop tartása',
          lead:
            '90 perces pitch felkészítés workshop a HUNBAN által kiválasztott 5 csapat számára. Visszajelzések, javaslatok angyalbefektetői szempontból.'
        }
      ]
    },
    techtra: {
      title: 'Techtra Zrt.',
      logo: partnerImages.techtra,
      offers: [
        {
          title: 'Szakmai konzultáció',
          lead:
            'Fókuszterületeihez illeszkedő témákban HSUP csapatok számára segítséget nyújt ötlet validációban, valamint tanácsadást és mentorálást kínál. Érettebb termék esetén, esetleges tesztelési terepet biztosít vagy elősegíti a piacra vitelt.'
        }
      ]
    },
    strt: {
      title: 'STRT Holding Zrt.',
      logo: partnerImages.strt,
      offers: [
        {
          title: 'Oktatási anyag',
          lead:
            'A Demo Day-re bejutott csapatok számára ingyenes hozzáférési lehetőséget biztosít a STRT Akadémia Tudástár tartalmaihoz.'
        }
      ]
    },
    neprajz: {
      title: 'Néprajzi Múzeum',
      logo: partnerImages.neprajz,
      offers: [
        {
          title: 'Kedvezményes coworking iroda alkalmi használatra',
          lead:
            'Csapatok számára szabad helyek függvényében kedvezményes asztalbérlési lehetőséget biztosít. A terem kényelmesen 12 fő számára biztosít asztalt, mely 16 főig bővíthető. Az első két óra ingyenes, minden további megkezdett óra 10% kedvezménnyel foglalható (bruttó 500 Ft/óra helyett 450 Ft/óradíj/asztal, 12 asztal együttes foglalása bruttó 6 000 ft/óra helyett bruttó 5 400 ft/óra). Minimum foglalási idő 4 óra.'
        },
        {
          title: 'Kedvezményes coworking iroda havi bérletet biztosít',
          lead:
            '1 hónapra, előre lekötve 4 nap esetén a havidíjból 25% kedvezménnyel (bruttó 160 000 Ft helyett 120 000 Ft) van lehetőség a teljes iroda bérlésére. Havi bérlet tartalmazza: 12-16 asztal 4 fix napra és egy alkalommal a térből közvetlenül nyíló tárgyaló is igénybe vehető (a fenti foglalás esetén bruttó 2500 Ft/fő/napidíj). A napi bérlés az Etknow könyvesbolt nyitvatartásához igazodik, vagyis október 1 -május 1. között 10:00-18:00-ig, május 1 -október 1. között 10:00-20:00-ig'
        }
      ]
    },
    enterprisegroup: {
      title: 'Enterprise Group',
      logo: partnerImages.enterprisegroup,
      offers: [
        {
          title: 'Tárgyi díj - HSUP Demo Day',
          lead:
            'Mérnöki szempontból legígéretesebbnek ítélt tevékenységet végző HSUP csapat számára max. 100.000 Ft értékű díjazást biztosít. '
        },
        {
          title: 'Solid Edge program részvétel',
          lead:
            'A Solid Edge „Friss diplomás programon” való végig vezetést, mentorálást biztosít, továbbá 1 éves Solid Edge ingyenes előfizetést ad a legjobb mérnöki tevékenységet végző csapat tagjainak részére.'
        },
        {
          title: 'Szakmai tanácsadás',
          lead:
            'Előzetes egyeztetés alapján tanévenként 2x30 perces szakmai tanácsadást nyújt max. 3 hallgatói csapat számára.'
        },
        {
          title: 'EPLM Solid Edge Tudásbázis hozzáférés',
          lead:
            'Igény szerint HSUP csapatok számára hozzáférést biztosít az EPLM Solid Edge Tudásbázishoz a projektek idejére.'
        },
        {
          title: 'SE diáklicenc biztosítása',
          lead: 'Igény szerint HSUP csapatok számára SE diáklicencet biztosít.'
        }
      ]
    },
    datatronic: {
      title: 'Datatronic',
      logo: partnerImages.datatronic,
      offers: [
        {
          title: 'Projektcsapatok támogatása',
          lead: 'Projektek mentorálása és támogatása.'
        },
        {
          title: 'Ingyenes szolgáltatások',
          lead:
            'Hosting, Cloud,  IaaS, SaaS szolgáltatásokat tudunk adni egy bizonyos periódusig ingyen a Startupok számára.'
        },
        {
          title: 'Irodahasználat',
          lead:
            'Irodai infrastruktúrát (elkülönített projekt szobát, tárgyalót, kivetítőt, monitorokat) tudunk biztosítani, max 6 főig egy csapatnak az együttműködés elősegítésére.'
        }
      ]
    },
    rackhost: {
      title: 'Rackhost Zrt.',
      logo: partnerImages.rackhost,
      offers: [
        {
          title: '10000 forint kedvezmény online szolgáltatás igénybevételéhez',
          lead:
            '10 000 forint értékű kreditet ajánl fel a legkiemelkedőbb 100 csapatnak, amit az online jelenlétük hatékony elindítására hasznosíthatnak. A Rackhost Zrt. széleskörű IT infrastruktúra szolgáltatásokat kínál, beleértve webtárhelyet, domain regisztrációt és üzleti levelezést is. Ezek segítségével a csapatok kreatív ötleteiket és vállalkozásaikat szélesebb közönség számára ismertté tehetik.'
        }
      ]
    },
    tappointment: {
      title: 'Tappointment',
      logo: partnerImages.tappointment,
      offers: [
        {
          title: 'Szakmai konzultáció',
          lead: 'Szakmai konzultáció/tanácsadás biztosítása 1-2 csapat számára'
        },
        {
          title: 'Workshop',
          lead: 'Workshop-ok - IT, üzleti és marketing területen'
        }
      ]
    },
    hvkik: {
      title: 'Heves Vármegyei Kereskedelmi és Iparkamara',
      logo: partnerImages.hvkik,
      offers: [
        {
          title: 'Szakmai mentoráció',
          lead: 'Szakmai mentort biztosít a programba'
        },
        {
          title: 'Pitch felkészítés',
          lead:
            '1 HUSP projekt csapat számára pitch felkészítést biztosít 2 x 2 órában.'
        },
        {
          title: 'Szakmai tanácsadás',
          lead:
            'Szakmai tanácsadás egy HSUP projektcsapat részére vállalkozásindítás témakörében 1 órában'
        }
      ]
    },
    morgens: {
      title: 'Morgens',
      logo: partnerImages.morgens,
      offers: [
        {
          title: 'Konzultáció',
          lead:
            '5 kiválasztott HSUP csapat számára 1 órás konzultációt biztosít turisztikai és szállodaipari területeken, online marketing stratégia vonatkozásában.'
        }
      ]
    },
    sivo: {
      title: 'SIVO Kft.',
      logo: partnerImages.sivo,
      offers: [
        {
          title: 'Szakmai tanácsadás',
          lead:
            '2-3 HSUP csapat számára érdeklődési területük mélyebb felfedezéséhez, internetes erőforrások kiaknázásához, illetve a fejlesztés világpiaci működésbe illesztéséhez szakmai tanácsadást nyújt online vagy offline formában'
        },
        {
          title: 'Mentoráció',
          lead:
            'Az ötletek validálása, az innováció fejlesztés, az üzleti modell felépítése és a piaci stratégia kialakítása, valamint a csapatmunka hatékonyságának maximalizálása érdekében mentorációt, konzultációt biztosít.'
        }
      ]
    },
    whiteunicorn: {
      title: 'White Unicorn',
      logo: partnerImages.whiteunicorn,
      offers: [
        {
          title: 'Demo Day-en való részvétel',
          lead:
            'A HSUP zárásaként tartott Demo Day rendezvényre szakértőt delegál'
        },
        {
          title: 'Felkészítés, teszt prezentáció',
          lead:
            'Legfeljebb 2 kiválasztott csapat számára a Demo Day-t megelőzően 1-1 alkalommal (2 óra) teszt pitch-elési lehetőséget biztosít és szakmai visszajelzéseket nyújt.'
        },
        {
          title: 'Pre-inkubációs lehetőség',
          lead:
            'A kiválasztott csapatok csatlakozhatnak a WU pre-inkubációs programjához, amit követően - megfelelő előrehaladás esetén - az inkubátor befektetési lehetőséget ajánl fel. Preferáltak az egészségügyi/orvosi technológiai projektek.'
        }
      ]
    },
    tapndrink: {
      title: "Tap'n'Drink",
      logo: partnerImages.tapndrink,
      offers: [
        {
          title: 'Közös sörözés/vacsora',
          lead:
            'Közös sörözés/vacsora és beszélgetés az érdeklődő hallgatókkal a vállalkozóvá válás nehézségeiről, lehetőségeiről, követelményekről. Hallgatók hozhatják saját kérdéseiket, témáikat. Hogyan vigyünk sikerre egy ötletet és hogyan égessük el a befektetett pénzt, ups and downs.'
        }
      ]
    },
    scaleagency: {
      title: 'scale agency',
      logo: partnerImages.scaleagency,
      offers: [
        {
          title: 'Prezenzáció design',
          lead:
            'Vizuális, szöveges és stratégiai tanácsadás/segítségnyújtás hatékony sales-orientált prezentációk elkészítésében.'
        }
      ]
    }
  };

  // Partners are ordered by object KEYS
  const partnersByAbc = Object.keys(partners)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: partners[key]
      }),
      {}
    );

  return partnersByAbc;
}
