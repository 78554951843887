/* eslint-disable react/prop-types */
import GatsbyImage from 'gatsby-image';
import React from 'react';
import Link from '../common/Link';
import usePartners from './hooks/usePartners';

export default function PartnerOverviewSection() {
  const leadStyle = { color: '#607D8B' };

  return (
    <section>
      <h2>HSUP partnerek</h2>
      {/* <h4 style={leadStyle}>
        Kattints a partnerek logójára, hogy lásd milyen ajánlatokat nyújtanak a
        csapatok számára!
      </h4> */}
      <ScrollableImages />
    </section>
  );
}

function ScrollableImages() {
  const partners = usePartners();

  return (
    <div className="mt-8 grid gap-4 grid-cols-2 xl:grid-cols-4">
      {Object.keys(partners).map(partnerKey => {
        const partner = partners[partnerKey];
        const { title, logo } = partner;

        return (
          <ScrollableImage
            key={title}
            img={logo}
            alt={title}
            partnerKey={partnerKey}
          />
        );
      })}
    </div>
  );
}

function ScrollableImage(props) {
  const { img, alt, partnerKey } = props;

  const borderStyle = {
    border: '2.35996px solid #CFD8DC',
    borderRadius: '4.71993px'
  };

  if (!img) {
    return null;
  }

  return (
    <Link to={partnerKey} title={alt}>
      <div className="bg-white p-2 h-52" style={borderStyle}>
        <GatsbyImage
          alt={alt}
          fluid={img.childImageSharp.fluid}
          imgStyle={{ objectFit: 'contain' }}
          className="h-48"
        />
      </div>
    </Link>
  );
}
