import { useStaticQuery, graphql } from 'gatsby';

const usePartnerImages = () =>
  useStaticQuery(graphql`
    query {
      innonic: file(relativePath: { eq: "partners/innonic.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      tokeportal: file(relativePath: { eq: "partners/tokeportal.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bosch: file(relativePath: { eq: "partners/bosch.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      oxo: file(relativePath: { eq: "partners/oxo.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      microsoft: file(relativePath: { eq: "partners/microsoft.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      pInvent: file(relativePath: { eq: "partners/p-invent.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      lunar: file(relativePath: { eq: "partners/lunar.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      vespucci: file(relativePath: { eq: "partners/vespucci.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mkb: file(relativePath: { eq: "partners/mkb.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      digitalFactory: file(relativePath: { eq: "partners/digital.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      startitup: file(relativePath: { eq: "partners/startitup.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      efott: file(relativePath: { eq: "partners/efott_24.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      szintezis: file(relativePath: { eq: "partners/szintezis.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bmeZ10: file(relativePath: { eq: "partners/bmez10.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      drukka: file(relativePath: { eq: "partners/drukka.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mol: file(relativePath: { eq: "partners/mol.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      smartFutureLab: file(relativePath: { eq: "partners/mvm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      startupCampus: file(relativePath: { eq: "partners/campus.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      nak: file(relativePath: { eq: "partners/nak.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bnlStart: file(relativePath: { eq: "partners/bnl.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      startItKh: file(relativePath: { eq: "partners/kh.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      checkInnTurisztikai: file(relativePath: { eq: "partners/checkin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      laserConsult: file(relativePath: { eq: "partners/laser.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      creativeAccelerator: file(relativePath: { eq: "partners/creative.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      tanacs: file(relativePath: { eq: "partners/tanacs.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      startupHungary: file(
        relativePath: { eq: "partners/startup_hungary.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      designTerminal: file(
        relativePath: { eq: "partners/design_terminal.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      izinta: file(relativePath: { eq: "partners/izinta.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      ivsz: file(relativePath: { eq: "partners/ivsz.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      greenBrother: file(relativePath: { eq: "partners/green_brother.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      meOutGroup: file(relativePath: { eq: "partners/meout_group.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      brancsKozosseg: file(
        relativePath: { eq: "partners/brancskozosseg.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      impactHubBp: file(relativePath: { eq: "partners/impact-hub.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      helloTomorrowHun: file(
        relativePath: { eq: "partners/hello-tomorrow.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      impactVentures: file(
        relativePath: { eq: "partners/impact-ventures.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      startupPlastic: file(
        relativePath: { eq: "partners/startup-plastic.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      eitHealth: file(relativePath: { eq: "partners/eit-health.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      vodafone: file(relativePath: { eq: "partners/vodafone.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      growthMagazin: file(relativePath: { eq: "partners/growth-magazin.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      cibBank: file(relativePath: { eq: "partners/cib-bank.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      pozi: file(relativePath: { eq: "partners/pozi.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      google: file(relativePath: { eq: "partners/google.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      otp: file(relativePath: { eq: "partners/otp.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      vivold: file(relativePath: { eq: "partners/vivold.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      diverzum: file(relativePath: { eq: "partners/diverzum.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      futureproof: file(relativePath: { eq: "partners/futureproof.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      sztnh: file(relativePath: { eq: "partners/sztnh.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      hunban: file(relativePath: { eq: "partners/hunban.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      techtra: file(relativePath: { eq: "partners/techtra.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      strt: file(relativePath: { eq: "partners/strt.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      neprajz: file(relativePath: { eq: "partners/neprajz.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mbh: file(relativePath: { eq: "partners/mbh.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      enterprisegroup: file(
        relativePath: { eq: "partners/enterprisegroup.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      datatronic: file(relativePath: { eq: "partners/datatronic.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      rackhost: file(relativePath: { eq: "partners/rackhost.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      tappointment: file(relativePath: { eq: "partners/tappointment.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      hvkik: file(relativePath: { eq: "partners/hvkik.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      morgens: file(relativePath: { eq: "partners/morgens.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      sivo: file(relativePath: { eq: "partners/sivo.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      whiteunicorn: file(relativePath: { eq: "partners/whiteunicorn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      tapndrink: file(relativePath: { eq: "partners/tapndrink.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      scaleagency: file(relativePath: { eq: "partners/scale-agency.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

export default usePartnerImages;
